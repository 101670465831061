import React, {
  useState, useCallback, useMemo, useRef,
} from 'react';
import {
  Box, Typography,
} from '@mui/material';
import Rack from './Rack';
import { useDatagridDialog } from './DatagridDialogContext';

const boxStyle = {
  backgroundColor: '#ecf2f5',
  border: '3px solid #e4e6e7',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  p: 1,
  gap: 5,
  transition: 'filter 0.3s ease',
};

export default function Zone({ zone }) {
  const [isHovered, setIsHovered] = useState(false);
  const clickStartTime = useRef(0);
  const {
    openDialog,
  } = useDatagridDialog();

  const containers = useMemo(() => {
    const nonNullContainers = [];
    zone.racks.forEach((rack) => {
      rack.slots.forEach((slot) => {
        if (slot.container !== null) {
          nonNullContainers.push(slot.container);
        }
      });
    });
    return nonNullContainers;
  }, [zone]);

  const handleMouseDown = useCallback(() => {
    clickStartTime.current = performance.now();
  }, []);

  const handleMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, []);

  const handleClick = useCallback((e) => {
    const clickEndTime = performance.now();
    const clickDuration = clickEndTime - clickStartTime.current;
    if (clickDuration < 300) {
      e.stopPropagation();
      openDialog(containers, Object.keys(containers[0]), zone.name);
    }
  }, [containers, openDialog, zone.name]);

  const RackMemo = useMemo(() => React.memo(Rack), []);

  return (
    <Box
      sx={{
        ...boxStyle,
        filter: isHovered ? 'brightness(1.04)' : 'brightness(1)',
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
    >
      <Typography sx={{ cursor: 'pointer' }} variant="h6">{zone.name}</Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 6,
        }}
      >
        {zone.racks.map((rack) => (
          <RackMemo key={rack.id} rack={rack} />
        ))}
      </Box>
    </Box>
  );
}
