import React from 'react';
import Box from '@mui/material/Box';
import ArrowBack from '@mui/icons-material/ArrowBack';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import IconButton from '@mui/material/IconButton';
import logo from 'staticFiles/labox-ws.svg';
import AccountBox from '@mui/icons-material/AccountBox';
import { Typography } from '@mui/material';
import LanguageSelect from './LanguageSelect';

export default function TopButtons(props) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        mt: 1,
        px: 5,
      }}
    >
      <LogoOrReturn />
      <Typography sx={{ fontWeight: 'bold' }}> Site 1</Typography>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          flexWrap: 'wrap',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        <IconButton
          aria-label="account"
          component={Link}
          to="account"
          sx={{ p: 0, fontSize: 37 }}
          color="primary"
        >
          <AccountBox fontSize="inherit" />
        </IconButton>
        <LanguageSelect />
      </Box>
    </Box>
  );
}

function LogoOrReturn() {
  const navigate = useNavigate();
  const location = useLocation();

  if (['/signin'].includes(location.pathname)) {
    return (
      <img
        src={logo}
        style={{ height: 40, objectFit: 'cover' }}
        alt="GC Labox"
      />
    );
  }

  if (['/'].includes(location.pathname)) {
    return (
      <img
        src={logo}
        style={{ height: 40, objectFit: 'cover' }}
        alt="GC Labox"
      />
    );
  }
  return (
    <IconButton size="large" onClick={() => navigate(-1)}>
      <ArrowBack />
    </IconButton>
  );
}
