import React, {
  useState, useMemo, useCallback, useRef,
} from 'react';
import {
  Box, Typography,
} from '@mui/material';
import { useDatagridDialog } from './DatagridDialogContext';
import Zone from './Zone';

const boxStyle = {
  backgroundColor: '#c1bab42e',
  border: '3px solid #63462D',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  p: 1,
  gap: 5,
  transition: 'filter 0.3s ease',
};

export default function Cellar({ cellar }) {
  const [isHovered, setIsHovered] = useState(false);
  const clickStartTime = useRef(0);
  const {
    openDialog,
  } = useDatagridDialog();

  const containers = useMemo(() => {
    const nonNullContainers = [];
    cellar.zones.forEach((zone) => {
      zone.racks.forEach((rack) => {
        rack.slots.forEach((slot) => {
          if (slot.container !== null) {
            nonNullContainers.push(slot.container);
          }
        });
      });
    });
    return nonNullContainers;
  }, [cellar]);

  const handleMouseDown = useCallback(() => {
    clickStartTime.current = performance.now();
  }, []);

  const handleMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, []);

  const handleClick = useCallback((e) => {
    const clickEndTime = performance.now();
    const clickDuration = clickEndTime - clickStartTime.current;
    if (clickDuration < 300) {
      e.stopPropagation();
      openDialog(containers, Object.keys(containers[0]), cellar.name);
    }
  }, [cellar.name, containers, openDialog]);

  const ZoneMemo = useMemo(() => React.memo(Zone), []);

  return (
    <Box
      sx={{
        ...boxStyle,
        borderColor: isHovered ? '#FFC300' : '#63462D',
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
    >
      <Typography variant="h4" sx={{ cursor: 'pointer' }}>{cellar.name}</Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 6,
        }}
      >
        {cellar.zones.map((zone) => (
          <ZoneMemo key={zone.id} zone={zone} />
        ))}
      </Box>
    </Box>
  );
}
