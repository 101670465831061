import * as React from 'react';

export default function FillingPercentage(props) {
  const { bgcolor, completed } = props;

  const containerStyles = {
    height: 20,
    width: '75%',
    backgroundColor: '#e0e0de',
    borderRadius: 4,
    border: '1px solid #000',
    position: 'relative',
  };

  const fillerStyles = {
    height: '100%',
    width: `${Number(completed)}%`,
    backgroundColor: bgcolor,
    borderRadius: 'inherit',
    textAlign: 'center',
  };

  const labelStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    padding: 5,
    color: 'black',
    fontWeight: 'bold',
  };

  return (
    <div style={containerStyles}>
      <div style={fillerStyles} />
      <span style={labelStyles}>{`${(Number(completed)).toFixed(0)}%`}</span>
    </div>
  );
}
