import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import { CircularProgress } from '@mui/material';
import MapContainer from './MapContainer2';
import generateContainersCsv from './generateContainersCsv';
import generateSlotsCsv from './generateSlotsCsv';
import zonesFile from '../csvEntities/zones.csv';
import racksFile from '../csvEntities/racks.csv';
import cellarsFile from '../csvEntities/cellars.csv';

export default function SiteMap() {
  const [state, setState] = useState('loading');
  const [cellars, setCellars] = useState([]);

  useEffect(() => {
    const loadCellars = async () => {
      // Load cellars from cellars.csv
      const cellarCSVResponse = await fetch(cellarsFile);
      const cellarCSVContent = await cellarCSVResponse.text();

      const { data: cellarData } = Papa.parse(cellarCSVContent, { header: true });

      // Load zones from zones.csv
      const zonesCSVResponse = await fetch(zonesFile);
      const zonesCSVContent = await zonesCSVResponse.text();

      const { data: zonesData } = Papa.parse(zonesCSVContent, { header: true });

      // Load racks from racks.csv
      const racksCSVResponse = await fetch(racksFile);
      const racksCSVContent = await racksCSVResponse.text();
      const { data: racksData } = Papa.parse(racksCSVContent, { header: true });

      // Generate slots CSV from generateCellarCSV
      const slotsCsv = generateSlotsCsv(racksCSVContent);

      // Generate containers CSV from generateContainersCSV
      const containersCsv = generateContainersCsv(slotsCsv);

      // Parse slots CSV
      const { data: slotsData } = Papa.parse(slotsCsv, { header: true });

      // Parse containers CSV
      const { data: containersData } = Papa.parse(containersCsv, { header: true });

      // Combine cellars, zones, racks, slots, and containers
      const updatedCellars = cellarData.map((cellar) => {
        const cellarId = cellar.id;
        const cellarZones = zonesData.filter((zone) => zone.cellar === cellarId);

        const updatedZones = cellarZones.map((zone) => {
          const zoneId = zone.id;
          const zoneRacks = racksData.filter((rack) => rack.zone === zoneId);

          const updatedRacks = zoneRacks.map((rack) => {
            const rackId = rack.id;
            const rackSlots = slotsData.filter((slot) => slot.rack === rackId);

            const slotWithContainer = rackSlots.map((slot) => {
              const slotContainer = containersData.find((container) => container.slot === slot.id);
              return {
                ...slot,
                container: slotContainer || null,
              };
            });

            return {
              ...rack,
              slots: slotWithContainer,
            };
          });

          return {
            ...zone,
            racks: updatedRacks,
          };
        });

        return {
          ...cellar,
          zones: updatedZones,
        };
      });

      setState('loaded');
      setCellars(updatedCellars);
    };

    loadCellars();
  }, []);

  if (state !== 'loaded') {
    return <CircularProgress />;
  }

  return (
    <MapContainer cellars={cellars} />
  );
}
