import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import {
  useSigninCheck,
  useUser,
  useFirestore,
} from 'reactfire';
import CircularProgress from '@mui/material/CircularProgress';
import {
  doc, getDoc,
  addDoc,
  collection,
} from 'firebase/firestore';
import useGlobal from 'global-state/store';
import {
  BrowserRouter, Routes, Route, useNavigate, useLocation,
} from 'react-router-dom';
import cgu from 'staticFiles/CGU.pdf';
import cgv from 'staticFiles/CGV.pdf';
import { useTranslation } from 'react-i18next';
import GlobalSnackbar from 'components/GlobalSnackbar';
import RemoteConfigStatus from 'components/RemoteConfigStatus';
import { Cookies, Contact } from 'labox-ws-commons';
import { getAnalytics } from 'firebase/analytics';
import PDFView from 'components/PDFView';
import MyAccount from 'components/account/MyAccount';
import SiteMap from 'components/map/SiteMap';
import SignInPage from './SignInPage';
import TopButtons from './TopButtons';
import Footer from './Footer';
import CellarGridView from './cellar/GridView';
import CellarAirtableGridView from './cellar/AirtableGridView';
import MenuViews from './MenuViews';

export default function Home() {
  return <RemoteConfigStatus defaultComponent={<UserSetupMenuAndMainPage />} />;
}

function UserSetupMenuAndMainPage() {
  const { status } = useSigninCheck();
  const { data: user } = useUser();
  const db = useFirestore();
  const [globalState, globalActions] = useGlobal();

  useEffect(() => {
    // eslint-disable-next-line no-promise-executor-return
    const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
    async function getUser(uid) {
      const userRef = doc(db, 'users', uid);
      const firestoreUser = await getDoc(userRef);
      return firestoreUser;
    }
    async function getUserPrivateData(uid) {
      const userRef = doc(db, 'users', uid, 'private', 'data');
      const firestoreUser = await getDoc(userRef);
      return firestoreUser;
    }
    async function fetchMultipleTimesUser(uid) {
      let firestoreUser = await getUser(uid);
      if (!firestoreUser.exists()) {
        await sleep(1000);
        firestoreUser = await getUser(uid);
        if (!firestoreUser.exists()) {
          await await sleep(3000);
          firestoreUser = await getUser(uid);
        }
      }
      return firestoreUser;
    }

    async function setupGlobalStateFromUser() {
      globalActions.setUserStatus('loading');
      await fetchMultipleTimesUser(user.uid);
      const firestoreUserPrivateData = await getUserPrivateData(user.uid);
      const userPrivateData = firestoreUserPrivateData.data();
      globalActions.setOrganizations(userPrivateData.organizations);
      globalActions.setActiveOrganization(userPrivateData.activeOrganization);
      return globalActions.setUserStatus('setupDone');
    }

    if (user?.uid !== undefined && globalState.userStatus === 'initial') {
      setupGlobalStateFromUser();
    }
  }, [db, globalActions, globalState.networkState, globalState.userStatus, user?.uid]);

  if (status === 'loading') {
    return (
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return <MainPage />;
}

function MainPage() {
  const { t } = useTranslation();
  const [, globalActions] = useGlobal();

  return (
    <BrowserRouter>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{
          minHeight: '80vh', display: 'flex', flexDirection: 'column',
        }}
        >
          <GlobalSnackbar />
          <TopButtons />
          <MainPageContent />
        </Box>
        <Footer />
        <Cookies
          cookieName="agreed-to-cookies-on-labox-stock-management"
          t={t}
          globalActions={globalActions}
        />
      </Box>
    </BrowserRouter>
  );
}

function MainPageContent(props) {
  const { data: signInCheckResult } = useSigninCheck();
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const [, globalActions] = useGlobal();
  const db = useFirestore();
  const analytics = getAnalytics();

  useEffect(() => {
    if (!signInCheckResult?.signedIn === true
      && location.pathname !== '/contact'
      && location.pathname !== '/cgu') {
      navigate('/signin');
    }
  }, [location.pathname, navigate, signInCheckResult?.signedIn]);

  const sendEmail = async (values) => {
    const emailContent = `Email: 
      ${values.email}

      Nom et entreprise: 
      ${values.nameAndCompany}

      Message: 
      ${values.message}`;

    await addDoc(
      collection(db, 'mail'),
      {
        to: 'contact@labox-apps.com',
        message: {
          subject: `[WS Organizations, Contact] ${values.nameAndCompany}`,
          text: emailContent,
        },
      },
    );
  };

  return (
    <Routes>
      <Route path="/" element={<SiteMap />} />
      <Route path="cellar/grid" element={<CellarGridView />} />
      <Route path="cellar/airtable-grid" element={<CellarAirtableGridView />} />
      <Route path="site-map" element={<SiteMap />} />

      <Route exact path="signin" element={<SignInPage />} />
      <Route exact path="account" element={<MyAccount />} />
      <Route
        path="contact"
        element={(
          <Contact
            t={t}
            navigate={navigate}
            globalActions={globalActions}
            analytics={analytics}
            sendEmail={sendEmail}
            signInCheckResult={signInCheckResult}
          />
)}
      />
      <Route path="cgu" element={<PDFView path={cgu} />} />
      <Route path="cgv" element={<PDFView path={cgv} />} />
      <Route
        path="*"
        element={(
          <main style={{ padding: '1rem' }}>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            <p>There's nothing here!</p>
          </main>
            )}
      />
    </Routes>
  );
}
