import * as React from 'react';
import { Paper } from '@mui/material';

export default function AirtableGridView() {
  return (
    <Paper sx={{ P: 3, height: 1000, width: '100%' }}>
      <iframe
        className="airtable-embed"
        src="https://airtable.com/embed/shrEONH6fnGyHVp0J?backgroundColor=cyan&viewControls=on"
        frameBorder="0"
        width="100%"
        height="100%"
        style={{ background: 'transparent', border: '1px solid #ccc' }}
        title="Airtable Embed"
      />
    </Paper>
  );
}
