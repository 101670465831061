import React from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import {
  Paper, Box, Fab, Button,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Cellar from './Cellar';
import DatagridDialog from './DatagridDialog';
import { DatagridDialogProvider } from './DatagridDialogContext';

export default function MapContainer({ cellars }) {
  const [isDragging, setIsDragging] = React.useState(false);

  const handleDragStart = () => {
    setIsDragging(true);
  };

  const handleDragEnd = () => {
    setIsDragging(false);
  };

  return (
    <Box
      sx={{
        position: 'relative',
        width: '100%',
        height: '90vh',
      }}
    >
      <TransformWrapper
        centerOnInit
        limitToBounds={false}
        initialScale={0.2}
        minScale={0.1}
        maxScale={6}
        onPanningStart={handleDragStart}
        onPanningStop={handleDragEnd}
        wheel={{ step: 0.06 }}
      >
        {({
          zoomIn, zoomOut, resetTransform, centerView,
        }: any) => (
          <>
            <Paper
              elevation={0}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                width: '100%',
                height: '100%',
                overflow: 'auto',
                cursor: isDragging ? 'grabbing' : 'grab',
                '&::before': {
                  content: "''",
                  boxShadow: 'inset 0 -8px 8px -8px rgba(0, 0, 0, 0.5), inset 0 8px 8px -8px rgba(0, 0, 0, 0.5)',
                  position: 'absolute',
                  pointerEvents: 'none',
                  top: 0,
                  left: 0,
                  width: '100%',
                  height: '100%',
                  zIndex: 999,
                },
              }}
            >
              <TransformComponent>
                <Cellars cellars={cellars} />
              </TransformComponent>
            </Paper>
            <Box
              sx={{
                position: 'absolute',
                bottom: '16px',
                right: '16px',
                zIndex: 2,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                gap: 1,
              }}
            >
              <Fab color="primary" size="small" aria-label="zoom-in" onClick={() => zoomIn()}>
                <AddIcon />
              </Fab>
              <Fab color="primary" size="small" aria-label="zoom-out" onClick={() => zoomOut()}>
                <RemoveIcon />
              </Fab>
              {/* <Button
                variant="contained"
                color="primary"
                size="small"
                aria-label="reset"
                onClick={() => { centerView(); resetTransform(); }}
              >
                réinitialiser
              </Button> */}
              <Button
                variant="contained"
                color="primary"
                size="small"
                aria-label="reset"
                onClick={() => centerView()}
              >
                centrer
              </Button>
            </Box>
          </>
        )}
      </TransformWrapper>
    </Box>
  );
}

function Cellars({ cellars }) {
  return (
    <DatagridDialogProvider>
      <>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            flexWrap: 'wrap',
            gap: 15,
            width: 5000,
            height: '100%',
            p: 5,
          }}
        >
          {cellars.map((cellar) => (
            <Cellar cellar={cellar} key={cellar.id} />
          ))}
        </Box>
        <DatagridDialog />
      </>
    </DatagridDialogProvider>
  );
}
