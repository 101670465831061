import React, {
  useState,
  useCallback,
  createContext, useContext, useMemo,
} from 'react';
import FillingPercentage from 'components/FillingPercentage';

const DatagridDialogContext = createContext();

export function DatagridDialogProvider({ children }) {
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [dataGridRows, setDataGridRows] = useState([]);
  const [dataGridColumns, setDataGridColumns] = useState([]);

  const replaceComma = useCallback((value) => {
    if (value !== null) {
      return value.toString().replace(/,/, '.');
    }
    return null;
  }, []);

  const createNewColumns = useCallback((columns) => columns.map((field) => {
    switch (field) {
      case 'fillingPercentage':
        return {
          field,
          headerName: field,
          width: 150,
          renderCell: (params) => {
            const { value } = params;
            return <FillingPercentage completed={replaceComma(value)} bgcolor="#99BD5B" />;
          },
        };
      default:
        return {
          field,
          headerName: field,
          width: 150,
          editable: true,
        };
    }
  }), [replaceComma]);

  const closeDialog = useCallback((event) => {
    event.stopPropagation();
    setDialogOpen(false);
    setDataGridRows([]);
    setDataGridColumns([]);
  }, []);

  const openDialog = useCallback((rows, columns, newTitle) => {
    setTitle(newTitle);
    setDataGridRows(rows);
    setDataGridColumns(createNewColumns(columns));
    setDialogOpen(true);
  }, [createNewColumns]);

  const value = useMemo(() => ({
    dataGridRows,
    dataGridColumns,
    openDialog,
    closeDialog,
    isDialogOpen,
    title,
  }), [dataGridRows, dataGridColumns, openDialog, closeDialog, isDialogOpen, title]);

  return (
    <DatagridDialogContext.Provider value={value}>
      {children}
    </DatagridDialogContext.Provider>
  );
}

export function useDatagridDialog() {
  const context = useContext(DatagridDialogContext);

  if (!context) {
    throw new Error('useDataGridDialog must be used within a DataGridDialogProvider');
  }

  return context;
}
