/* eslint-disable no-plusplus */
import React, { useMemo } from 'react';
import { Box, Typography } from '@mui/material';
import Slot from './Slot';

export default function Rack({ rack }) {
  const { length, height, slots } = rack;

  const grid = useMemo(() => {
    const newGrid = [];
    for (let level = 1; level <= height; level++) {
      const row = [];
      for (let position = 1; position <= length; position++) {
        const slot = slots.find(
          (s) => s.rack === rack.id
            && s.level === level.toString()
            && s.position === position.toString(),
        );
        row.push(slot);
      }
      newGrid.push({ id: generateUniqueId(), row });
    }
    return newGrid.reverse();
  }, [length, height, slots, rack]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 1,
      }}
    >
      <Typography variant="h8">{rack.name}</Typography>
      {grid.map((row, rowIndex) => (
        <Box
          key={row.id}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 1,
            marginLeft: rowIndex % 2 === 1 ? '52px' : '0',
          }}
        >
          {row.row.map((slot, columnIndex) => (
            <Slot key={slot.id} slot={slot} />
          ))}
        </Box>
      ))}
    </Box>
  );
}

function generateUniqueId() {
  return Math.random().toString(36).substr(2, 9);
}
