/* eslint-disable no-plusplus */
const Papa = require('papaparse');

export default function generateSlotsCSV(racksData) {
// Parse the racks CSV
  const racksResult = Papa.parse(racksData, { header: true });
  const racks = racksResult.data;

  // Generate the slots based on racks data
  const slots = [];
  let slotId = 1;

  racks.forEach((rack) => {
    const { id: rackId, length, height } = rack;

    const slotsPerLevel = length;

    for (let level = 1; level <= height; level++) {
      for (let position = 1; position <= slotsPerLevel; position++) {
        const slot = {
          id: slotId,
          name: `Emplacement ${slotId}`,
          rack: rackId,
          position,
          level,
        };

        slots.push(slot);
        slotId++;
      }
    }
  });

  // Convert slots data to CSV
  const slotsCsv = Papa.unparse(slots);

  return slotsCsv;
}
