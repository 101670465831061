import React from 'react';
import { Button, Typography, Link as MUILink } from '@mui/material';
import { useTranslation } from 'react-i18next';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import Box from '@mui/material/Box';
import Refresh from '@mui/icons-material/Refresh';
import version from 'staticFiles/version.txt';
import { Link } from 'react-router-dom';

export default function Footer() {
  const { t } = useTranslation();
  const [appVersion, setappVersion] = React.useState('');

  React.useEffect(() => {
    fetch(version)
      .then((r) => r.text())
      .then((text) => {
        setappVersion(text);
      });
  }, []);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      gap: 1,
      mt: 2,
      mb: 3,
      px: 5,
    }}
    >

      <Typography component="span" variant="body2" style={{ marginTop: 10, width: '100%' }}>
        {t('welcome.contact_footer')}
        {' '}

        <MUILink
          component={Link}
          to="/contact"
          color="primary"
          sx={{ fontWeight: 'bold' }}
        >
          contact@labox-apps.com
        </MUILink>
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexFlow: 'row',
          flexWrap: 'wrap',
          justifyContent: 'center',
          alignItems: 'stretch',
          gap: 1,
          mt: 1,
        }}
      >
        <Button
          sx={{ flexGrow: 1 }}
          variant="contained"
          color="primary"
          size="small"
          component={Link}
          to="/cgu"
          endIcon={<PdfIcon fontSize="small" />}
        >
          {t('welcome.gtcu')}
        </Button>
        <Button
          sx={{ flexGrow: 1 }}
          variant="contained"
          component={Link}
          to="/cgv"
          color="primary"
          size="small"
          endIcon={<PdfIcon fontSize="small" />}
        >
          {t('welcome.cgv')}
        </Button>
        <Button
          sx={{ flexGrow: 1 }}
          variant="contained"
          component={Link}
          to="/privacy-policy"
          color="primary"
          size="small"
          endIcon={<PdfIcon fontSize="small" />}
        >
          {t('welcome.privacy_policy')}
        </Button>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          alignItems: 'center',
          flexWrap: 'wrap',
          gap: 1,
        }}
      >
        <Typography variant="caption">
          {t('settings.app_version', { appVersion })}
        </Typography>

        <Button
          variant="text"
          size="small"
          onClick={() => window.location.reload()}
          endIcon={<Refresh />}
        >
          <Typography variant="caption">
            {t('reload')}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
}
