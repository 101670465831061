import React, { useState } from 'react';
import { useSigninCheck } from 'reactfire';
import { useTranslation } from 'react-i18next';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Cookies from 'universal-cookie';

export default function LanguageSelectToggle(props) {
  const { t, i18n } = useTranslation();
  const { height } = props;
  const [locale, setLocale] = useState(t('locale'));
  // eslint-disable-next-line no-unused-vars
  const { statusAuth, data: signInCheckResult } = useSigninCheck();

  const handleLocaleChange = (event, newLocale) => {
    if (newLocale !== null) {
      setLocale(newLocale);
      i18n.changeLanguage(newLocale, () => {});
      const cookies = new Cookies();
      cookies.set('saved_locale', newLocale);
    }
  };

  return (
    <ToggleButtonGroup
      sx={{ height }}
      value={locale}
      exclusive
      onChange={handleLocaleChange}
      aria-label="text alignment"
    >
      <ToggleButton size="small" value="en" aria-label="centered">
        EN
      </ToggleButton>
      <ToggleButton size="small" value="fr" aria-label="centered">
        FR
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
