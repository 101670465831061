/* eslint-disable no-plusplus */
// Import Papa Parse library (ensure you have included the papaparse.js file)
const Papa = require('papaparse');

// Function to generate containers CSV
export default function generateContainersCSV(slotsCsv) {
  // Parse the slots CSV
  const slotsResult = Papa.parse(slotsCsv, { header: true });
  const slots = slotsResult.data;

  // Group slots by racks and then levels
  const racks = {};
  slots.forEach((slot) => {
    if (!racks[slot.rack]) {
      racks[slot.rack] = {};
    }
    if (!racks[slot.rack][slot.level]) {
      racks[slot.rack][slot.level] = [];
    }
    racks[slot.rack][slot.level].push(slot);
  });

  const containers = [];

  // For each rack, randomly select range for containers
  Object.keys(racks).forEach((rackId) => {
    const rackSlots = racks[rackId];

    let minContainerIndex = generateRandomNumber(0, Math.floor((rackSlots[1].length - 1) / 8));
    let maxContainerIndex = generateRandomNumber(
      Math.ceil((rackSlots[1].length - 1) / 4 * 3),
      rackSlots[1].length - 1,
    );

    Object.keys(rackSlots).sort().forEach((level, index, levels) => {
      const levelSlots = rackSlots[level];

      for (let i = minContainerIndex; i < maxContainerIndex; i++) {
        const slot = levelSlots[i];
        const container = generateContainerFromSlot(slot);
        containers.push(container);
      }
      if (minContainerIndex < maxContainerIndex - 1) {
        minContainerIndex += generateRandomNumber(
          1,
          Math.max(1, Math.floor((maxContainerIndex - minContainerIndex) / 3)),
        );
        maxContainerIndex -= generateRandomNumber(
          1,
          Math.max(1, Math.floor((maxContainerIndex - minContainerIndex) / 3)),
        );
      }
    });
  });

  // Convert containers data to CSV
  const containersCSV = Papa.unparse(containers, { header: true });

  return containersCSV;
}

function generateContainerFromSlot(slot) {
  const { id: slotId } = slot;

  const container = {
    id: slotId,
    name: `${slotId}`,
    slot: slotId,
    type: 'Container Type',
    totalCapacity: 355,
    heightUnderBung: 70,
    diagonal: '',
    usableCapacityAt20C: 350,
    brandyType: 'Cognac',
    batchId: generateRandomBatchId(),
    volumeAt20C: generateRandomNumber(0, 350),
    TAVat20C: generateRandomNumber(12, 70),
    filling: '',
    availableVolumeAt20C: '',
    fillingPercentage: '',
    updateDate: generateRandomDate('01/01/2021', '31/12/2023'),
    supplier: 'jaugeur1',
    description: '',
    acquisitionDate: generateRandomDate('01/01/2018', '31/12/2022'),
  };

  // Calculate filling and fillingPercentage
  container.filling = container.volumeAt20C;
  container.fillingPercentage = (container.volumeAt20C / container.totalCapacity) * 100;

  return container;
}

// Helper function to generate a random batchId
function generateRandomBatchId() {
  const batchIds = ['EDVL01', 'EDVL02', 'EDVL03'];
  const randomIndex = Math.floor(Math.random() * batchIds.length);
  return batchIds[randomIndex];
}

// Helper function to generate a random number between min and max (inclusive)
function generateRandomNumber(min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

// Helper function to generate a random date between start and end (inclusive)
function generateRandomDate(start, end) {
  const startDate = new Date(start);
  const endDate = new Date(end);
  const randomTimestamp = startDate.getTime() + Math.random() * (endDate.getTime() - startDate.getTime());
  const randomDate = new Date(randomTimestamp);
  return randomDate.toLocaleDateString('fr-FR');
}
