import React, {
  useCallback, useMemo, useRef,
} from 'react';
import {
  Box, Typography,
} from '@mui/material';
import { useDatagridDialog } from './DatagridDialogContext';

const slotBoxStyle = {
  width: 44,
  height: 44,
  border: '2px dashed #999898bf',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'filter 0.3s ease',
  cursor: 'pointer',
  '&:hover': {
    filter: 'brightness(1.5)',
  },
};

const containerBoxStyle = {
  width: 40,
  height: 40,
  border: '3px solid #63462D',
  borderRadius: '50%',
  backgroundColor: 'ourOrange.main',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

export default function Slot({ slot }) {
  const clickStartTime = useRef(0);

  const {
    openDialog,
  } = useDatagridDialog();

  const handleMouseDown = useCallback(() => {
    clickStartTime.current = performance.now();
  }, []);

  const handleClick = useCallback((e) => {
    const clickEndTime = performance.now();
    const clickDuration = clickEndTime - clickStartTime.current;
    if (clickDuration < 300) {
      e.stopPropagation();
      openDialog(
        slot.container ? [slot.container] : [],
        slot.container ? Object.keys(slot.container) : [],
        slot.name,
      );
    }
  }, [openDialog, slot.container, slot.name]);

  const ContainerMemo = useMemo(() => React.memo(Container), []);

  return (
    <Box
      sx={slotBoxStyle}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
    >
      { slot.container && (<ContainerMemo container={slot.container} />)}
    </Box>
  );
}

function Container({ container }) {
  const containerStyle = {
    ...containerBoxStyle,
    // eslint-disable-next-line max-len
    background: `linear-gradient(to top, orange ${container.fillingPercentage}%, transparent ${container.fillingPercentage}%)`,
  };

  return (
    <Box
      sx={containerStyle}
    >
      <Typography variant="caption">
        {container.name}
      </Typography>
    </Box>
  );
}
