import React, {
  useMemo,
} from 'react';
import {
  Box, Button, Dialog, DialogContent, Typography,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import CloseIcon from '@mui/icons-material/Close';
import { useDatagridDialog } from './DatagridDialogContext';

export default function DatagridDialog() {
  const {
    closeDialog, isDialogOpen, title,
  } = useDatagridDialog();

  const DialogContainerMemo = useMemo(() => React.memo(DialogContainer), []);

  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      open={isDialogOpen}
      onClose={closeDialog}
    >

      <DialogContent>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: 2,
          }}
        >
          <Typography variant="body1">
            {title}
          </Typography>
          <Button
            variant="contained"
            onClick={closeDialog}
          >
            <CloseIcon />
          </Button>
        </Box>
        <DialogContainerMemo />
      </DialogContent>

    </Dialog>
  );
}

function DialogContainer() {
  const {
    dataGridRows, dataGridColumns,
  } = useDatagridDialog();

  return (
    <DataGrid
      rows={dataGridRows}
      columns={dataGridColumns}
      disableRowSelectionOnClick
    />
  );
}
