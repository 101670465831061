import * as React from 'react';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import Papa from 'papaparse';
import { Paper } from '@mui/material';
import FillingPercentage from 'components/FillingPercentage';
import data from './data.csv';

export default function DataGridDemo() {
  const replaceComma = (value) => {
    if (value !== null) {
      return value.toString().replace(/,/, '.');
    }
    return null;
  };

  const [rows, setRows] = React.useState([]);
  const [columns, setColumns] = React.useState([]);

  React.useEffect(() => {
    const fetchData = async () => {
      const response = await fetch(data);
      const csvData = await response.text();
      const parsedCsv = Papa.parse(csvData, {
        header: true,
        dynamicTyping: true,
        skipEmptyLines: true,
        delimiter: ';',
      });
      const customColumns: GridColDef[] = parsedCsv.meta.fields.map((field) => ({
        field,
        headerName: field,
        width: 150,
        editable: true,
      }));

      const newColumns: GridColDef[] = customColumns.map((column) => {
        switch (column.field) {
          case 'Code Emplacement':
            return {
              ...column,
              headerName: 'Code Emplacement',
              width: 200,
            };
          case '% de niveau':
            return {
              ...column,
              headerName: '% de niveau',
              width: 150,
              renderCell: (params) => {
                const { value } = params;
                return <FillingPercentage completed={replaceComma(value)} bgcolor="#99BD5B" />;
              },
            };
          case 'Capacité totale (L)':
            return {
              ...column,
              headerName: 'Capacité totale (L)',
              width: 200,
            };
          case 'Capacité utile à 20°C (L)':
            return {
              ...column,
              headerName: 'Capacité utile à 20°C (L)',
              type: 'number',
              width: 220,
            };
          default:
            return column;
        }
      });
      setRows(parsedCsv.data);
      setColumns(newColumns);
    };
    fetchData();
  }, []);

  return (
    <Paper sx={{ P: 3, height: 1000, width: '100%' }}>
      <DataGrid
        getRowId={(row) => row['Code Emplacement']}
        rows={rows}
        columns={columns}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 100,
            },
          },
        }}
        disableRowSelectionOnClick
      />
    </Paper>
  );
}
